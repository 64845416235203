import React from "react";
import Button from 'react-bootstrap/Button';

require("./DInfo.scss");

const DInfo = (props) => {

  return (
    <div className={props.dflex}>
      <div className="imgdiv" >
        {props.image ? <img src={props.image} /> : ""}
      </div>
      <div className="textdiv">
        <div>
          <div className="pt-4">
            <p>{props.textmatter}</p>
          </div>

          <div className="pt-5 pb-5" >
            <a href={props.link}>
              <Button className="btn rs-btn">Visit site →</Button>
            </a>
            {/* <button className={props.buttoncolor} >Find out More</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DInfo;