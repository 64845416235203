import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import AaJumbotron from "../components/AaJumbotron/AaJumbotron";
import Banner from "../components/Banner/Banner";
import DInfo from "../components/DInfo/DInfo";

export const CaseStudyTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  heroimage,
  sampleimage,
  helmet,
  link,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section news">
      {helmet || ''}
      <div className="overlayhero tophero">
        <AaJumbotron
          title={"Case Study: " + title}
          featuredimage={
            heroimage &&
            heroimage.childImageSharp.fluid
          }
        />
      </div>
      <div className="mainInfo">
        <DInfo
          dflex="row1"
          buttoncolor="btnbg1"
          textmatter={description}
          image={sampleimage && sampleimage.childImageSharp.fluid.src}
          link={link}
        />
      </div>
      {/* <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">

            {tags && tags.length ? (
              <div className="tagListContainer">
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div> */}
      <Banner bannerColour="#3b9ed5" bannerText="Start your project with us today" bannerButtonText="Get in touch" bannerButtonLink="/contact-us" />
    </section>
  )
}

CaseStudyTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
}

const CaseStudy = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <CaseStudyTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        heroimage={post.frontmatter.heroimage}
        sampleimage={post.frontmatter.sampleimage}
        // helmet={<Helmet title={`${post.frontmatter.title} | Case Study`} />}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        link={post.frontmatter.link}
      />
    </Layout>
  )
}

CaseStudy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default CaseStudy

export const pageQuery = graphql`
  query CaseStudyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        link
        heroimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sampleimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 1200, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`
